@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;600;700&display=swap');

@import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import "main.content.scss";
@import "project/project.prices";
@import "project/project.spreadsheet";
@import "elements/cropper";
@import "elements/drag";

/* zou in tailwind moeten zitten maar is niet zo? (versie?) */
.whitespace-nowrap {
  white-space: nowrap;
}
.modal-body {
  max-height:80vh;
}

.modal-wrapper {
  z-index:1000 !important;
}

.modal-body form {
  width:100%;
}

.modal-body form h1 {
  margin-top: 0 !important;
}

.view-switcher {
  top: -25px;
}

.edit-group {
  left: -30px;
}

.CircularProgressbar-path {
  stroke: rgb(126, 58, 242) !important;
}

.CircularProgressbar-trail {
  stroke: gray !important;
}

.CircularProgressbar-text {
  fill: rgb(126, 58, 242) !important;
}

.CircularProgressbar-background {
  fill: green !important;
}


.koko-modal header button[aria-label="close"] {
  display: none !important;
}

.z-100 {
  z-index: 100;
}

.z-300 {
  z-index: 300;
}

.z-500 {
  z-index: 500;
}

.ql-editor {
  height: 200px !important;
}

.quill {
  border-radius: 5px;
}


/* TODO: aanpassen */

.mal-10 {
  margin-left: 50px;
}

.mal-20 {
  margin-left: 75px;
}

.mal-30 {
  margin-left: 100px;
}

.mal-40 {
  margin-left: 125px;
}

.mal-50 {
  margin-left: 150px;
}


.specification-article {
  .article-actions {
    display: none;

  }

  &:hover .article-actions {
    display: flex;
  }
}

.map-icon {
  left: -50px;
  top: 25px;
}

.MuiTab-root {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}

.title-article-3, .title-article-4, .title-article-5, .title-article-6 {
  font-size: 1.4rem !important;
  margin-bottom: 10px !important;
}

.title-article-2 {
  margin-left: 15px;
}

.breadcrumb-item {
  display: flex;

  &:not(:first-child):after {
    content: '>';
    display: flex;
    padding: 0px 5px;
  }
}

.activity-block img.large {
  max-height: 300px;
  object-fit: cover;
}

.cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  min-width: 60px;
  min-height: 60px;
}

.avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: fill;
  overflow: hidden;
  display: block;
  border-radius: 100%;
}

.project-info-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  overflow: hidden;
  display: block;
  border-radius: 30px;
}

.project-info-file {
  width: 200px;
  height: 200px;
  border-radius: 30px;
}

.layout-block {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(100px);
  height: 4rem;
}

.drop-shadow {
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.bg-alternating-column {
  background-color: #f1edfb;
  z-index:2;
  position:relative;
}



.autosaving {
  transform: translateX(0) !important;
  position: absolute !important;
  bottom: 80px !important;
  right: 0 !important;
  left: 0 !important;
  transform: translateY(0) !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 300;
  color: black !important;
  cursor: default !important;
  pointer-events: none;
  box-shadow: none !important;
  width: 200px;
  background: none !important;

  .Toastify__progress-bar {
    background: black !important;
  }

  button {
    opacity: 0;
  }
}

div[contenteditable="true"] {
  //font-family: sans-serif;
  //min-height: 75px;
  //border: 1px dashed #aaa;
  //padding: 5px;
  //margin-right: 20px;
}

.types-table {
  td {
    vertical-align: baseline;
  }
}